<template>
    <v-container fluid v-if="pedido">
      <template>
        <v-row>
          <v-col cols='12' md='6'>
            <!-- <v-row>
              <v-col> -->
            <v-card>
              <v-card-title>
                <v-icon large class="mr-2">mdi-account-cash</v-icon> {{ pedido.documento }}{{ displayNome }}
              </v-card-title>
              <v-card-text>
                <v-simple-table dense>
                  <tbody>
                    <tr>
                      <td class="font-weight-bold">Status</td>
                      <td v-if="pedido.avaliado_por_mim">{{ displayStatus(pedido.status) }}</td>
                      <td v-else class="indigo--text">Avalie a operação para ver este dado</td>
                    </tr>
                  </tbody>
                </v-simple-table>
              </v-card-text>
            </v-card>
          <!-- </v-col> -->
            <!-- </v-row> -->
            <v-row>
              <v-col>
                <v-expansion-panels>
                  <v-expansion-panel @click="fetchPxdata()">
                    <v-expansion-panel-header class="font-weight-bold">Resumo PXSearch</v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <PXSummary
                        v-if="pedido.pxdata"
                        :pedido="pedido"
                        :loading="loading"
                        @summaryChanged="fetchData"
                      />
                      <v-card v-else>
                        <v-card-title><v-icon large class="mr-2">mdi-shield-search</v-icon> Resumo PXSearch</v-card-title>
                        <v-card-text>
                          <v-overlay
                            absolute
                            opacity="1"
                            color="white"
                            light
                            z-index="4"
                          >
                            <span class="indigo--text title">Documento não encontrado</span>
                          </v-overlay>
                        </v-card-text>
                      </v-card>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-col>
            </v-row>
          </v-col>
        <v-col cols='12' md='6'>
          <Avaliacoes
          :pedido="pedido"
          :nome="displayNome"
          @avals_updated="updated_avals()"
          @avals_data="dataFilter()"
          />
        </v-col>
      </v-row>
    </template>
    <v-card>
      <v-card-text class="text-center">
        <v-progress-circular
          indeterminate
        ></v-progress-circular>
      </v-card-text>
    </v-card>
    <v-expansion-panels focusable multiple>
      <!-- <v-expansion-panel v-if="serasa" class="mb-2">
        <v-expansion-panel-header>Serasa</v-expansion-panel-header>
        <v-expansion-panel-content>
          <Serasa :avalId="serasa" />
        </v-expansion-panel-content>
      </v-expansion-panel> -->
      <v-expansion-panel v-if="bigDataSancoes" class="mb-2">
        <v-expansion-panel-header>Big Data Corp. Sanções</v-expansion-panel-header>
        <v-expansion-panel-content>
          <BigDataCorpSancoes :avalId="bigDataSancoes" />
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel v-if="bigDataProcessos" class="mb-2">
        <v-expansion-panel-header>Big Data Corp. Processos</v-expansion-panel-header>
        <v-expansion-panel-content>
          <BigDataCorpProcessos :avalId="bigDataProcessos" />
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel v-if="protestos" class="mb-2">
        <v-expansion-panel-header>Central de Protestos</v-expansion-panel-header>
        <v-expansion-panel-content>
          <CentralDeProtestos :avalId="protestos" />
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel v-if="debitos" class="mb-2">
        <v-expansion-panel-header>Certidão de Débitos</v-expansion-panel-header>
        <v-expansion-panel-content>
          <CertidaoDeDebitos :avalId="debitos" />
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel v-if="reusedInfo" class="mb-2">
        <v-expansion-panel-header>Informações reutilizadas</v-expansion-panel-header>
        <v-expansion-panel-content>
          <ReusedInfo :avalId="reusedInfo" />
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel v-if="cnjMandados" class="mb-2">
        <v-expansion-panel-header>CNJ Mandados</v-expansion-panel-header>
        <v-expansion-panel-content>
          <CnjMandados :avalId="cnjMandados" />
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel v-if="cnjMandadosPapyrus" class="mb-2">
        <v-expansion-panel-header>CNJ Mandados Papyrus</v-expansion-panel-header>
        <v-expansion-panel-content>
          <CnjMandadosPapyrus :avalId="cnjMandadosPapyrus" />
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel v-if="googleTermosNegativos" class="mb-2">
        <v-expansion-panel-header>Google com Termos Negativos</v-expansion-panel-header>
        <v-expansion-panel-content>
          <GoogleTermosNegativos :avalId="googleTermosNegativos" />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
import dateFmt from '../mixins/dateFmt'
import displayStatus from '../mixins/displayStatus'
/* import Serasa from './Serasa' */
import BigDataCorpSancoes from './BigDataCorpSancoes'
import BigDataCorpProcessos from './BigDataCorpProcessos'
import CentralDeProtestos from './CentralDeProtestos'
import CertidaoDeDebitos from './CertidaoDeDebitos'
import ReusedInfo from './ReusedInfo'
import CnjMandados from './CnjMandados'
import CnjMandadosPapyrus from './CnjMandadosPapyrus'
import GoogleTermosNegativos from './GoogleTermosNegativos'


import PXSummary from './PXSummary'
import Avaliacoes from './Avaliacoes'

export default {
  components: {
    PXSummary, Avaliacoes, BigDataCorpSancoes, BigDataCorpProcessos, CentralDeProtestos, CertidaoDeDebitos, ReusedInfo, CnjMandados, CnjMandadosPapyrus, GoogleTermosNegativos/*  Serasa */
  },

  props: {
    pedidoid: Number,
  },

  mixins: [dateFmt, displayStatus],

  created () {
    this.init()
  },

  destroyed () {
    // clearInterval(this.reloader)
  },

  data: () => ({
    loading: true,
    error: null,
    reloader: null,
    serasa: null,
    bigDataSancoes: null,
    bigDataProcessos: null,
    protestos: null,
    debitos: null,
    reusedInfo: null,
    cnjMandados: null,
    cnjMandadosPapyrus: null,
    googleTermosNegativos: null,
    model: null
  }),

  computed: {
    ...mapState(['pedidos','avaliacoes','account',]),

    pedido: function() {
      return this.$store.getters['pedidos/getPedidoById'](parseInt(this.pedidoid))
    },

    finished: function() {
      return !this.loading && this.pedido.pxdata && !this.pedido.pxdata.summary.pending_report_list.length
    },

    displayNome: function() {
      if(!this.pedido.pxdata) {
        return ''
      }

      if ('nome_set' in this.pedido.pxdata.summary &&
                    this.pedido.pxdata.summary.nome_set.length > 0) {
        return ' - ' + this.pedido.pxdata.summary.nome_set[0].value
      } else if ('razaosocial_set' in this.pedido.pxdata.summary &&
                    this.pedido.pxdata.summary.razaosocial_set.length > 0) {
        return ' - ' + this.pedido.pxdata.summary.razaosocial_set[0].value
      }

      return ''

    },
  },

  methods: {
    async init(){
      await this.fetchData()
    },

    async fetchPxdata () {
      if (!this.pedido.pxdata) {
        this.loading = true
        await this.$store.dispatch('pedidos/get_pedido_pxdata', this.pedidoid)
        this.loading = false
      }
    },

    async fetchData () {
      this.loading = true
      await this.$store.dispatch('pedidos/get_pedido', this.pedidoid)
      this.loading = false
    },

    async updated_avals() {
      await this.fetchData()
    },

    dataFilter() {
      const labelSerasa = 'Serasa Relato'
      const labelSancoesBDC = 'Sanções (BigDataCorp)'
      const labelProcessosBDC = 'Processos Juridicos (BigDataCorp)'
      const labelProtestos = 'Central de Protestos'
      const labelCertidaoDebito = 'Certidão de Débito'
      const labelReusedInfo = 'Informações Reutilizadas'
      const labelCNJMandados = 'CNJ Mandados'
      const labelCNJMandadosPapyrus = 'CNJ Mandados (Papyrus)'
      const labelGoogle = 'Busca Google c/ Termos Negativos'

      if (this.avaliacoes && this.avaliacoes.avaliacoes) {
        for (let i = 0; i < this.avaliacoes.avaliacoes.length; i++) {
          const aval = this.avaliacoes.avaliacoes[i]
          if (aval.aval_task_status !== 'CP') {
            continue
          }
          
          if (aval.review_model_obj.label === labelSerasa) {
            this.serasa = aval.id
          }
          else if (aval.review_model_obj.label === labelSancoesBDC) {
            this.bigDataSancoes = aval.id
          }
          else if (aval.review_model_obj.label === labelProcessosBDC) {
            this.bigDataProcessos = aval.id
          }
          else if (aval.review_model_obj.label === labelProtestos) {
            this.protestos = aval.id
          }
          else if (aval.review_model_obj.label === labelCertidaoDebito) {
            this.debitos = aval.id
          }
          else if (aval.review_model_obj.label === labelReusedInfo) {
            this.reusedInfo = aval.id
          }
          else if (aval.review_model_obj.label === labelCNJMandados) {
            this.cnjMandados = aval.id
          }
          else if (aval.review_model_obj.label === labelCNJMandadosPapyrus) {
            this.cnjMandadosPapyrus = aval.id
          }
          else if (aval.review_model_obj.label === labelGoogle) {
            this.googleTermosNegativos = aval.id
          }
        }
      }
    }
  },

};
</script>
