import Vue from 'vue'
import Vuex from 'vuex'

import account from './modules/account'
import pedidos from './modules/pedidos'
import reviews from './modules/reviews'
import reviewmodels from './modules/reviewmodels'
import avaliacoes from './modules/avaliacoes'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    account: account,
    pedidos: pedidos,
    reviews: reviews,
    reviewmodels: reviewmodels,
    avaliacoes: avaliacoes,
  },
})
