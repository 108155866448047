<template>
  <div>
    <v-progress-circular v-if="isLoading || !avalData" indeterminate class="mt-2" />
    <div v-else>
      <div v-if="avalData[0] === null && avalData[1] === null && avalData[2] === null">
        <v-simple-table dense >
          <thead>
            <tr>
              <th>Nada consta</th>
            </tr>
          </thead>
        </v-simple-table>
      </div>
      <div v-else>
        <v-card elevation="3" class="pa-1 mt-2">
          <v-card-title class="subtitle-2">
            Leads
          </v-card-title>
          <v-simple-table dense>
            <thead>
              <tr>
                <th>IP</th>
                <th>DOC</th>
                <th>URL</th>
                <th>Email</th>
                <th>Lead ID</th>
                <th>Endereço</th>
                <th>Criado em</th>
                <th>Celular</th>
                <th>Tel. comercial</th>
              </tr>
            </thead>
            <tbody v-for="(a, i) in avalData" :key="i">
              <tr v-for="(l, i) in a ? a.leads : ''" :key="i">
                <td>{{ l.ip }}</td>
                <td>{{ l.doc }}</td>
                <td>{{ l.url }}</td>
                <td>{{ l.email }}</td>
                <td>{{ l.lead_id }}</td>
                <td>{{ l.endereco }}</td>
                <td>{{ l.created_at ? formattedDate3(l.created_at) : '' }}</td>
                <td>{{ l.telefone_celular }}</td>
                <td>{{ l.telefone_comercial }}</td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-card>
        <v-card elevation="3" class="pa-1 mt-3">
          <v-card-title class="subtitle-2">
            Entidades
          </v-card-title>
          <v-simple-table dense>
            <thead>
              <tr>
                <th>IP</th>
                <th>DOC</th>
                <th>URL</th>
                <th>Email</th>
                <th>Lead ID</th>
                <th>Endereço</th>
                <th>Criado em</th>
                <th>Celular</th>
                <th>Tel. comercial</th>
              </tr>
            </thead>
            <tbody v-for="(a, i) in avalData" :key="i">
              <tr v-for="(l, i) in a ? a.entidades : ''" :key="i">
                <td>{{ l.ip }}</td>
                <td>{{ l.doc }}</td>
                <td>{{ l.url }}</td>
                <td>{{ l.email }}</td>
                <td>{{ l.lead_id }}</td>
                <td>{{ l.endereco }}</td>
                <td>{{ l.created_at ? formattedDate3(l.created_at) : '' }}</td>
                <td>{{ l.telefone_celular }}</td>
                <td>{{ l.telefone_comercial }}</td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import dateFmt from '../mixins/dateFmt'

export default {
  props: {
    avalId: Number,
  },

  mixins: [
    dateFmt
  ],

  computed: {
    ...mapState(['avaliacoes'])
  },

  data() {
    return {
      isLoading: true,
      avalData: null
    }
  },

  mounted() {
    this.init()
  },

  methods: {
    init () {
      this.isLoading = true
      this.avalData = null

      this.$store.dispatch('avaliacoes/get_data', this.avalId, 'serasa').then((data) => {
        this.avalData = data
        this.isLoading = false
      })
    } 
  }
}
</script>
