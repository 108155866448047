<template>
    <v-menu open-on-hover bottom>
        <template v-slot:activator="{ on }">
            <span v-on="on">
                <v-icon :color="displayAvalColor(resultado)" class="ma-0">
                    {{ displayAvalIcon(resultado) }}
                </v-icon>
                  {{avals.length}}
            </span>
        </template>
        <v-list dense>
            <v-list-item>
                <v-list-item>
                    <v-list-item-content>
                        <v-list-item-title class="Title">
                            {{ title }}
                        </v-list-item-title>
                        <v-list-item-subtitle>
                            Total: {{ avals.length }}
                        </v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
                <v-divider/>
            </v-list-item>
            <v-list-item v-for="aval in avals" :key="aval.id">
                <v-list-item-title>
                    <v-icon class="mr-1"
                    :color="displayAvalColor(aval.resultado)">
                    {{ displayAvalIcon(aval.resultado) }}
                    </v-icon>
                    <span v-if="aval.scraper_label"> {{ aval.scraper_label }} </span>
                    <span v-else-if="aval.review_model_obj"> {{ aval.review_model_obj.label }} </span>
                    <span v-else-if="aval.creator"> {{ aval.creator }} </span>
                    <span v-else> Avaliação </span>
                </v-list-item-title>
            </v-list-item>
        </v-list>
    </v-menu>
</template>

<script>
import avalStatus from "../mixins/avalStatus"

export default {
    props: {
        title: String,
        resultado: String,
        avals: Array,
    },

    mixins: [
        avalStatus
    ],
}
</script>
