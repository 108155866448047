<template>
  <v-card>
    <v-card-title>{{ title }}</v-card-title>
    <v-overlay
      v-if="loading"
      absolute
      class="text-center">
      <v-progress-circular
        indeterminate
      ></v-progress-circular>
    </v-overlay>
    <v-list dense>
      <div v-for="(s, i) in list.pedidos" :key="i">
        <PedidosListItem :pedido="s" />
        <v-divider
          v-if="i + 1 < list.pedidos.length"
          :key="i"
        ></v-divider>
      </div>
    </v-list>
    <v-card-actions>
      <v-btn text
        :disabled="list.pedidos_prev === null"
        @click="fetchData(list.pedidos_prev, resultado)"
      >
        <v-icon>mdi-chevron-left</v-icon> Anterior
      </v-btn>
      <v-spacer></v-spacer>
      {{ list.pedidos_pg }} / {{ list.pedidos_tot_pgs }}
      <v-spacer></v-spacer>
      <v-btn text
        :disabled="list.pedidos_next === null"
        @click="fetchData(list.pedidos_next, resultado)"
      >
        Próximo <v-icon>mdi-chevron-right</v-icon>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapState } from 'vuex'

import PedidosListItem from './PedidosListItem.vue'

export default {
  components: {
    PedidosListItem,
  },

  props: {
    resultado: String,
    reviewid: Number,
  },

  async created () {
    this.loading = true
    await this.fetchData(null, this.resultado)
    this.loading = false
  },

  data: () => ({
    loading: false,
  }),

  computed: {
    ...mapState(['pedidos', 'account',]),

    list(){
      if (this.resultado == 'E')
        return this.pedidos.erros
      if (this.resultado == 'R')
        return this.pedidos.reprovados
      return this.pedidos.total
    },

    title(){
      if (this.resultado == 'E')
        return 'Pedidos com Erros'
      if (this.resultado == 'R')
        return 'Pedidos com Reprovações'
      return 'Pedidos de Review'
    }
  },

  methods: {
    async fetchData (url, resultado) {
      await this.$store.dispatch('pedidos/get_pedidos', {url, resultado, review: this.reviewid})
    },
  }

};
</script>
