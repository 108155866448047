<template>
  <div>
    <v-progress-circular v-if="isLoading || !avalData" indeterminate class="mt-2"/>
    <div v-else>
      <v-tabs v-if="avalData && avalData.Result && avalData.Result[0]">
        <!-- Sobre -->
        <v-tab v-if="avalData.Result[0].Lawsuits && avalData.Result[0].Lawsuits.Lawsuits &&  avalData.Result[0].Lawsuits.Lawsuits.length > 0" href="#sobre">Sobre</v-tab>
        <v-tab-item value="sobre">
          <v-card v-if="avalData.Result[0].Lawsuits && avalData.Result[0].Lawsuits.Lawsuits &&  avalData.Result[0].Lawsuits.Lawsuits.length > 0" elevation="3" class="pa-1 mt-2">
            <v-simple-table dense >
              <template v-slot:default>
                <thead>
                  <tr>
                    <th>Tipo</th>
                    <th>Estado</th>
                    <th>Valor</th>
                    <th>Número do processo</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(l, i) in avalData.Result[0].Lawsuits.Lawsuits" :key="i">
                    <td>{{ l.Type }}</td>
                    <td>{{ l.State }}</td>
                    <td>{{ l.Value }}</td>
                    <td>{{ l.Number }}</td>
                    <td>{{ l.Status }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card>
        </v-tab-item>
        <!-- Partes -->
        <v-tab v-if="avalData.Result[0].Lawsuits && avalData.Result[0].Lawsuits.Lawsuits[0] && avalData.Result[0].Lawsuits.Lawsuits[0].Parties"  href="#parties">Partes do processo</v-tab>
        <v-tab-item value="parties">
          <v-card v-if="avalData.Result[0].Lawsuits && avalData.Result[0].Lawsuits.Lawsuits[0] && avalData.Result[0].Lawsuits.Lawsuits[0].Parties" elevation="3" class="pa-1 mt-2">
            <v-simple-table dense>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th>CPF/CNPJ</th>
                    <th>Nome</th>
                    <th>Polaridade</th>
                    <th>Tipo específico</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(p, i) in avalData.Result[0].Lawsuits.Lawsuits[0].Parties" :key="i">
                    <td>{{ p.Doc }}</td>
                    <td>{{ p.Name }}</td>
                    <td>{{ p.Polarity }}</td>
                    <td>{{ p.PartyDetails.SpecificType }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card>
        </v-tab-item>
        <!-- Atualizações -->
        <v-tab v-if="avalData.Result[0].Lawsuits && avalData.Result[0].Lawsuits.Lawsuits[0] && avalData.Result[0].Lawsuits.Lawsuits[0].Updates"  href="#updates">Andamento do processo</v-tab>
        <v-tab-item value="updates">
          <v-card v-if="avalData.Result[0].Lawsuits && avalData.Result[0].Lawsuits.Lawsuits[0] && avalData.Result[0].Lawsuits.Lawsuits[0].Updates" elevation="3" class="pa-1 mt-2">
            <v-simple-table dense >
              <template v-slot:default>
                <thead>
                  <tr>
                    <th>Conteúdo</th>
                    <th>Data de publicação</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(u, i) in avalData.Result[0].Lawsuits.Lawsuits[0].Updates" :key="i">
                    <td>
                      <span v-if="u.Content.length > 95">
                        <showModal 
                          title="Conteúdo"
                          :body="u.Content"
                          button="Mostrar"
                        />
                      </span>
                      <span v-else>{{ u.Content }}</span>
                    </td>
                    <td>{{ u.PublishDate ? formattedDate3(u.PublishDate) : '' }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card>
        </v-tab-item>
        <!-- Resumo -->
        <v-tab href="#resumo">Resumo</v-tab>
        <v-tab-item value="resumo">
          <v-card v-if="avalData.Result[0].Lawsuits && avalData.Result[0].Lawsuits.Lawsuits[0]" elevation="3" class="pa-1 mt-2">
            <v-simple-table dense>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th>Data de encerramento</th>
                    <th>Nome do tribunal</th>
                    <th>Natureza do processo</th>
                    <th>Instância</th>
                    <th>Dias de processo</th>
                    <th>Data de intimação</th>
                    <th>Comarca</th>
                    <th>Assunto principal</th>
                    <th>Data do último movimento</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td> {{ avalData.Result[0].Lawsuits.Lawsuits[0].CloseDate ? formattedDate3(avalData.Result[0].Lawsuits.Lawsuits[0].CloseDate) : '' }} </td>
                    <td> {{ avalData.Result[0].Lawsuits.Lawsuits[0].CourtName }} </td>
                    <td> {{ avalData.Result[0].Lawsuits.Lawsuits[0].CourtType }} </td>
                    <td> {{ avalData.Result[0].Lawsuits.Lawsuits[0].CourtLevel }} </td>
                    <td> {{ avalData.Result[0].Lawsuits.Lawsuits[0].LawSuitAge }} </td>
                    <td> {{ avalData.Result[0].Lawsuits.Lawsuits[0].NoticeDate ? formattedDate3(avalData.Result[0].Lawsuits.Lawsuits[0].NoticeDate) : '' }} </td>
                    <td> {{ avalData.Result[0].Lawsuits.Lawsuits[0].JudgingBody }} </td>
                    <td> {{ avalData.Result[0].Lawsuits.Lawsuits[0].MainSubject }} </td>
                    <td> {{ avalData.Result[0].Lawsuits.Lawsuits[0].LastMovementDate ? formattedDate3(avalData.Result[0].Lawsuits.Lawsuits[0].LastMovementDate) : ''}} </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card>
          <v-card v-if="avalData.Result[0] && avalData.Result[0].Lawsuits" elevation="3" class="pa-1 mt-3">
            <v-simple-table dense>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th>Total de processos</th>
                    <th>Data da última ação judicial</th>
                    <th>Data do Primeiro Processo</th>
                    <th>Processos judiciais dos últimos 365 dias</th>
                    <th>Total de processos como outros</th>
                    <th>Total de processos como autor</th>
                    <th>Total de processos como réu</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td> {{ avalData.Result[0].Lawsuits.TotalLawsuits }} </td>
                    <td> {{ avalData.Result[0].Lawsuits.LastLawsuitDate ? formattedDate3(avalData.Result[0].Lawsuits.LastLawsuitDate) : '' }} </td>
                    <td> {{ avalData.Result[0].Lawsuits.FirstLawsuitDate ? formattedDate3(avalData.Result[0].Lawsuits.FirstLawsuitDate) : '' }} </td>
                    <td> {{ avalData.Result[0].Lawsuits.Last365DaysLawsuits }} </td>
                    <td> {{ avalData.Result[0].Lawsuits.TotalLawsuitsAsOther }} </td>
                    <td> {{ avalData.Result[0].Lawsuits.TotalLawsuitsAsAuthor }} </td>
                    <td> {{ avalData.Result[0].Lawsuits.TotalLawsuitsAsDefendant }} </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card>
        </v-tab-item>
    </v-tabs>
    </div>

  </div>
</template>


<script>
import { mapState } from 'vuex'
import dateFmt from '../mixins/dateFmt'
import ShowModal from '../components/Modal.vue'

export default {
  props: {
    avalId: Number,
  },

  mixins: [
    dateFmt
  ],

  components: {
    ShowModal
  },
    
  computed: {
    ...mapState(['avaliacoes'])
  },

  data() {
    return {
      isLoading: true,
      avalData: null
    }
  },

  mounted() {
    this.init()
  },

  methods: {
    init () {
      this.isLoading = true
      this.avalData = null

      this.$store.dispatch('avaliacoes/get_data', this.avalId, 'serasa').then((data) => {
        this.avalData = data
        this.isLoading = false
      })
    } 
  }
}
</script>
