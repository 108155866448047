<template>
  <v-container fluid>
    <template v-if="!loading">
      <v-row>
        <v-col>
          <v-card>
            <v-card-title>
              {{ reviewmodel.label }}
            </v-card-title>
            <v-card-text>
              <v-simple-table dense>
                <tbody>
                  <tr>
                    <td>Tipo</td>
                    <td>{{ reviewmodel.tipo_de_review }}</td>
                  </tr>
                  <tr>
                    <td>Criador</td>
                    <td>{{ reviewmodel.creator }}</td>
                  </tr>
                  <tr>
                    <td>Criado</td>
                    <td>{{ formattedDate(reviewmodel.created) }}</td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col>
          <v-card>
            <v-card-title>
              Sumário
            </v-card-title>
            <v-card-text>
              <v-simple-table dense>
                <thead>
                  <tr>
                    <th></th>
                    <th>#</th>
                    <th>%</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Pedidos Avaliados</td>
                    <td>{{ reviewmodel.summary.total }}</td>
                    <td>-</td>
                  </tr>

                  <tr>
                    <td>Aprovados</td>
                    <td>{{ reviewmodel.summary.aprovados }}</td>
                    <td>{{ reviewmodel.summary.total ? ((reviewmodel.summary.aprovados/reviewmodel.summary.total) * 100.0).toFixed(2) : '-' }}</td>
                  </tr>

                  <tr>
                    <td>Reprovados</td>
                    <td>{{ reviewmodel.summary.reprovados }}</td>
                    <td>{{ reviewmodel.summary.total ? ((reviewmodel.summary.reprovados/reviewmodel.summary.total) * 100.0).toFixed(2) : '-' }}</td>
                  </tr>

                  <tr>
                    <td>Pendentes</td>
                    <td>{{ reviewmodel.summary.pendentes }}</td>
                    <td>{{ reviewmodel.summary.total ? ((reviewmodel.summary.pendentes/reviewmodel.summary.total) * 100.0).toFixed(2) : '-' }}</td>
                  </tr>

                  <tr>
                    <td>Erros</td>
                    <td>{{ reviewmodel.summary.erros }}</td>
                    <td>{{ reviewmodel.summary.total ? ((reviewmodel.summary.erros/reviewmodel.summary.total) * 100.0).toFixed(2) : '-' }}</td>
                  </tr>

                  <tr>
                    <td>Analisando</td>
                    <td>{{ reviewmodel.summary.analisando }}</td>
                    <td>{{ reviewmodel.summary.total ? ((reviewmodel.summary.analisando/reviewmodel.summary.total) * 100.0).toFixed(2) : '-' }}</td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row v-if="reviewmodel.description">
        <v-col>
          <div>{{reviewmodel.description}}</div>
        </v-col>
        <br>
      </v-row>
    </template>
    <v-card v-else>
      <v-card-text class="text-center">
        <v-progress-circular
          indeterminate
        ></v-progress-circular>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
import dateFmt from '../mixins/dateFmt'


export default {
  components: {
  },

  props: {
    reviewid: Number,
  },

  mixins: [dateFmt],

  async created () {
    await this.fetchData()
  },

  data: () => ({
    loading: true,
    reviewmodel: null,
  }),

  computed: {
    ...mapState(['reviewmodels',]),
  },

  methods: {
    async fetchData () {
      this.loading = true
      this.reviewmodel = await this.$store.dispatch('reviewmodels/get_reviewmodel_by_id', this.reviewid)
      this.loading = false
    },
  },

};
</script>
