<template>
  <div>
    <v-progress-circular v-if="isLoading || !avalData" indeterminate class="mt-2"/>
    <div v-else>
      <v-card v-if="avalData.length > 0"  elevation="3" class="pa-1 mt-2">
        <v-simple-table dense>
          <thead>
            <tr>
              <th>UF</th>
              <th>Cartório</th>
              <th>Município</th>
              <th>Valor protestado</th>
              <th>Nº de títulos protestados</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(cp, i) in avalData" :key="i">
              <td>{{ cp.uf }}</td>
              <td>{{ cp.cartorio }}</td>
              <td>{{ cp.municipio }}</td>
              <td>{{ 'R$' + cp.valor_protestado }}</td>
              <td>{{ cp.nr_titulos_protestados }}</td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-card>
      <v-card v-else>
      <v-simple-table dense class="mt-2">
        <thead>
          <tr>
            <th>
              Nada consta
            </th>
          </tr>
        </thead>
      </v-simple-table>
    </v-card>
    </div>
  </div>
</template>


<script>
import { mapState } from 'vuex'
import dateFmt from '../mixins/dateFmt'

export default {
  props: {
    avalId: Number,
  },

  mixins: [
    dateFmt
  ],

  computed: {
    ...mapState(['avaliacoes'])
  },

  data() {
    return {
      isLoading: true,
      avalData: null
    }
  },

  mounted() {
    this.init()
  },

  methods: {
    init () {
      this.isLoading = true
      this.avalData = null

      this.$store.dispatch('avaliacoes/get_data', this.avalId, 'serasa').then((data) => {
        this.avalData = data
        this.isLoading = false
      })
    } 
  }
}
</script>
