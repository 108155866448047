<template>
  <div>
    <v-navigation-drawer
      v-model="drawer"
      clipped
      fixed
      app
    >
      <v-list>
        <template v-if="$store.getters['account/loggedin']">
          <v-list-item to="/">
            <v-list-item-action>
              <v-icon>mdi-home</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Home</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
        <template v-else>
          <AppLoginDialog>
            <template v-slot:activator="{ on }">
              <v-list-item v-on="on">
                <v-list-item-action>
                  <v-icon>mdi-login-variant</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>Entrar</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </AppLoginDialog>
        </template>
        <v-list-item>
          <v-list-item-content class="mt-1 text-sm-center font-weight-light">
            <p>©2021 Up.p</p>
            <p><a href="mailto:pxreview@upp.com.br">pxreview@upp.com.br</a></p>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar
      app
      fixed
      clipped-left
      clipped-right
      >
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title>
        <!-- <v-icon x-large color="light-blue darken-3">mdi-account-cowboy-hat</v-icon>
        <v-icon x-large color="light-blue darken-3">mdi-layers-search</v-icon>
        <v-icon x-large color="light-blue darken-3">mdi-exit-run</v-icon>
        <v-icon x-large color="light-blue darken-3">mdi-ghost-off</v-icon>
        <v-icon x-large color="light-blue darken-3">mdi-head-alert</v-icon> -->
        <v-icon x-large color="light-blue darken-3">mdi-owl</v-icon>
        PXReview<sup>α</sup>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <template v-if="$store.getters['account/loggedin']">
          <AppAccountMenu />
        </template>
        <template v-else>
          <AppLoginDialog></AppLoginDialog>
        </template>
      </v-toolbar-items>
    </v-app-bar>
  </div>
</template>

<script>
import { mapState } from 'vuex'

import AppLoginDialog from './AppLoginDialog'
import AppAccountMenu from './AppAccountMenu'

export default {
  components: {
    AppLoginDialog,
    AppAccountMenu,
  },

  computed: mapState([
    'account',
  ]),

  data() {
    return {
      drawer: null,
    }
  },

  methods: {
  },
}
</script>
