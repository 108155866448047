<template>
  <div>
    <AppNav />
    <v-main>
      <PedidoDetails
        v-if="$store.getters['account/loggedin']"
        :pedidoid="pedidoid">
      </PedidoDetails>
      <v-container v-else fluid grid-list-md>
        <h1>Por favor, faça login para usar o sistema.</h1>
      </v-container>
    </v-main>
  </div>
</template>

<script>
import { mapState } from 'vuex'

import AppNav from '../components/AppNav';
import PedidoDetails from '../components/PedidoDetails.vue';

export default {
  components: {
    AppNav,
    PedidoDetails,
  },

  props: {
    pedidoid: Number,
  },

  computed: mapState([
    'account'
  ]),
};
</script>
