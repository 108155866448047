<template>
  <div>
    <v-progress-circular v-if="isLoading || !avalData" indeterminate class="mt-2"/>
    <div v-else>
      <div v-if="avalData && avalData.Result && avalData.Result[0]">
        <v-card elevation="3" class="pa-1 mt-2">
          <v-card-title class="subtitle-2">
            Sanções
          </v-card-title>
          <v-simple-table v-if="avalData.Result[0].KycData && avalData.Result[0].KycData.SanctionsHistory && avalData.Result[0].KycData.SanctionsHistory.length > 0"  dense>
              <thead class="font-weight-bolder">
                <tr>
                  <th>Tipo</th>
                  <th>Órgão sancionador</th>
                  <th>Nome original</th>
                  <th>Nome da sanção</th>
                  <th>Categoria da sanção</th>
                  <th>Inicio de Vigência da sanção</th>
                  <th>Final de Vigência da sanção</th>
                  <th>Taxa de correspondência (Match Rate)</th>
                  <th>Data da última atualização</th>
                  <th>Nome continua sancionado</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(sh, i) in avalData.Result[0].KycData.SanctionsHistory" :key="i">
                  <td>{{ sh.Type}}</td>
                  <td>{{ sh.Source }}</td>
                  <td>{{ sh.Details.OriginalName }}</td> 
                  <td>{{ sh.Details.SanctionName }}</td>
                  <td>{{ sh.StandardizedSanctionType }}</td>
                  <td>{{ sh.CreationDate ? formattedDate3(sh.CreationDate) : '' }}</td>
                  <td>{{ sh.EndDate ? formattedDate3(sh.EndDate) : '' }}</td>
                  <td>
                    {{ sh.MatchRate  }}
                    <v-icon v-if="sh.MatchRate >= 80" small class="ml-1 mb-1" color="red">mdi-alert-circle-outline</v-icon>
                  </td>
                  <td>{{ sh.LastUpdateDate ? formattedDate3(sh.LastUpdateDate) : '' }}</td>
                  <td>{{ sh.IsCurrentlyPresentOnSource === true ? 'sim' : sh.IsCurrentlyPresentOnSource === false ? 'não' : '' }}</td>
                </tr>
              </tbody>
          </v-simple-table>
          <v-simple-table v-else dense>
            <thead>
              <tr>
                <th>Não possui sanção</th>
              </tr>
            </thead>
          </v-simple-table>
        </v-card>
        <v-card v-if="avalData.Result[0].KycData" elevation="3" class="pa-1 mt-3">
          <v-card-title class="subtitle-2">
            Resumo
          </v-card-title>
          <v-simple-table dense>
              <thead>
                <tr>
                  <th>Sanções dos últimos 365 dias</th>
                  <th>Atualmente está sancionado</th>
                  <th>Ocorrência de PEP nos últimos 5 anos</th>
                  <th>Histórico de doador eleitoral</th>
                  <th>Valor total da doação eleitoral</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{{ avalData.Result[0].KycData.Last365DaysSanctions }}</td>
                  <td>{{ avalData.Result[0].KycData.IsCurrentlySanctioned === true ? 'sim' : avalData.Result[0].KycData.IsCurrentlySanctioned === false ? 'não' : '' }}</td>
                  <td>{{ avalData.Result[0].KycData.Last5YearsPEPOccurence }}</td>
                  <td>{{ avalData.Result[0].KycData.IsHistoricalElectoralDonor === true ? 'sim' : avalData.Result[0].KycData.IsHistoricalElectoralDonor === false ? 'não' : ''}}</td>
                  <td>{{ avalData.Result[0].KycData.TotalElectoralDonationAmount }}</td>
                </tr>
              </tbody>
          </v-simple-table>
        </v-card>
      </div>
    </div>
  </div>
</template>


<script>
import { mapState } from 'vuex'
import dateFmt from '../mixins/dateFmt'

export default {
  props: {
    avalId: Number,
  },

  mixins: [
    dateFmt
  ],

  computed: {
    ...mapState(['avaliacoes'])
  },

  data() {
    return {
      isLoading: true,
      avalData: null
    }
  },

  mounted() {
    this.init()
  },

  methods: {
    init () {
      this.isLoading = true
      this.avalData = null

      this.$store.dispatch('avaliacoes/get_data', this.avalId, 'serasa').then((data) => {
        this.avalData = data
        this.isLoading = false
      })
    } 
  }
}
</script>
