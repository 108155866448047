<template>
  <v-simple-table dense>
    <thead>
      <tr>
        <th class="text-center"><v-icon small class="mr-1" color="blue">mdi-wrench-outline</v-icon> <ReportStatusListHover title="Fontes Pendentes" :reports="pedido.pxdata.summary.pending_report_list"></ReportStatusListHover></th>
        <th class="text-center"><v-icon small class="mr-1" color="green">mdi-check-circle-outline</v-icon> <ReportStatusListHover title="Fontes Consultadas" :reports="pedido.pxdata.summary.finished_report_list"></ReportStatusListHover></th>
        <th class="text-center"><v-icon small class="mr-1" color="red">mdi-alert-circle-outline</v-icon> <ReportStatusListHover title="Fontes com Erro" :reports="pedido.pxdata.summary.failed_report_list"></ReportStatusListHover></th>
        <th class="text-center"><v-icon small class="mr-1" color="yellow">mdi-cash-usd</v-icon> <ReportStatusListHover title="Fontes Opcionais" :reports="pedido.pxdata.summary.optional_report_list"></ReportStatusListHover></th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <th class="text-center">
          {{ pedido.pxdata.summary.pending_report_list.length }}
        </th>
        <th class="text-center">
          {{ pedido.pxdata.summary.finished_report_list.length }}
        </th>
        <th class="text-center">
          <v-btn small text outlined
            v-if="pedido.pxdata.summary.failed_report_list.length"
            @click="retryFailedReports"
          >
            {{ pedido.pxdata.summary.failed_report_list.length }}: Tentar Novamente
          </v-btn>
          <span v-else>{{ pedido.pxdata.summary.failed_report_list.length }}</span>
        </th>
        <th class="text-center">
          {{ pedido.pxdata.summary.optional_report_list.length }}
        </th>
      </tr>
    </tbody>
  </v-simple-table>
</template>

<script>
import ReportStatusListHover from './ReportStatusListHover'

export default {
  components: {
    ReportStatusListHover,
  },

  props: {
    pedido: Object,
  },

  methods: {
    retryFailedReports: async function() {
      await this.$store.dispatch('pedidos/retry_failed_reports_pedido', this.pedido.id)
      this.$emit('summaryChanged')
    },
  },
};
</script>
