<template>
  <v-card>
    <v-card-title>{{ manual ? 'Analistas' : 'Modelos Automáticos' }}</v-card-title>
    <v-overlay
      v-if="loading"
      absolute
      class="text-center">
      <v-progress-circular
        indeterminate
      ></v-progress-circular>
    </v-overlay>
    <v-list dense>
      <div v-for="(rm, i) in list.list" :key="i">
        <ReviewModelListItem :reviewmodel="rm"/>
        <v-divider
          v-if="i + 1 < list.length"
          :key="i"
        ></v-divider>
      </div>
    </v-list>
    <v-card-actions>
      <v-btn text
        :disabled="list.prev === null"
        @click="fetchData(list.prev, manual)"
      >
        <v-icon>mdi-chevron-left</v-icon> Anterior
      </v-btn>
      <v-spacer></v-spacer>
      {{ list.pg }} / {{ list.tot_pgs }}
      <v-spacer></v-spacer>
      <v-btn text
        :disabled="list.next === null"
        @click="fetchData(list.next, manual)"
      >
        Próximo <v-icon>mdi-chevron-right</v-icon>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapState } from 'vuex'
import ReviewModelListItem from './ReviewModelListItem.vue'


export default {
  components: {
    ReviewModelListItem,
  },

  props: {
    manual: Boolean
  },

  async created () {
    this.loading = true
    await this.fetchData(null, this.manual)
    this.loading = false
  },

  data: () => ({
    loading: false,
  }),

  computed: {
    ...mapState(['reviewmodels', 'account',]),

    list() {
      if (this.manual === true) {
        return this.reviewmodels.reviewmodels_manual
      }
      else {
        return this.reviewmodels.reviewmodels
      }
    }
  },

  methods: {
    async fetchData (url, manual) {
      await this.$store.dispatch(`reviewmodels/get_reviewmodels`, {url, manual})
    },
  }

};
</script>
