<template>
  <div>
    <v-overlay
      v-if="loading"
      absolute
      class="text-center">
      <v-progress-circular
        indeterminate
      ></v-progress-circular>
    </v-overlay>
    <v-card v-if="!loading">
      <v-card-title class="pb-0">
        <v-icon large class="mr-2" :color="displayAvalColor(pedido.resultado)">{{displayAvalIcon(pedido.resultado)}}</v-icon> Avaliações
        <v-spacer />
        <!-- <v-btn
          x-small
          outlined
          fab
          color="indigo"
          @click="reavaliar"
          class="mr-2"
        >
            <v-icon>mdi-sync</v-icon>
        </v-btn> -->
        <NovaAvaliacao
          v-if="!pedido.avaliado_por_mim || !pedido.avaliacao_definitiva"
          :pedido="pedido"
          :avaliacao="null"
          @avals_updated="updated_avals()"
        />
      </v-card-title>
      <v-card-text>
        <v-container class="py-0">
          <v-row align="start" justify="start">
            <v-col cols="2">
                <AvaliacoesListHover
                :title="'AVALIAÇÕES APROVADAS'"
                :resultado="status.APROVADO"
                :avals="filter_avals_by_status(status.APROVADO)"/>
            </v-col>
            <v-col v-if="contains(status.REPROVADO)" cols="2">
                <AvaliacoesListHover
                :title="'AVALIAÇÕES REPROVADAS'"
                :resultado="status.REPROVADO"
                :avals="filter_avals_by_status(status.REPROVADO)"/>
            </v-col>
            <v-col v-if="contains(status.PENDENTE)" cols="2">
                <AvaliacoesListHover
                :title="'AVALIAÇÕES PENDENTES'"
                :resultado="status.PENDENTE"
                :avals="filter_avals_by_status(status.PENDENTE)"/>
            </v-col>
            <v-col v-if="contains(status.ERRO)" cols="2">
                <AvaliacoesListHover
                :title="'AVALIAÇÕES C/ ERROS'"
                :resultado="status.ERRO"
                :avals="filter_avals_by_status(status.ERRO)"/>
            </v-col>
            <v-col v-if="contains(null)" cols="2">
                <AvaliacoesListHover
                :title="'AVALIAÇÕES EM EXECUÇÃO'"
                :resultado="null"
                :avals="filter_avals_by_status(null)"/>
            </v-col>
          </v-row>
        </v-container>
        <v-card v-if="pedido.avaliacao_definitiva">
          <v-card-title class="py-0">
            <v-list dense>
              <v-list-item>
                  <v-icon x-large :color="displayAvalColor(pedido.avaliacao_definitiva.resultado)">
                    {{displayAvalIcon(pedido.avaliacao_definitiva.resultado)}}
                  </v-icon>
                <v-icon color="yellow darken-2" class="mr-2">
                  mdi-shield-star-outline
                </v-icon>
                <v-list-item-content>
                  <span 
                    v-if="pedido.avaliacao_definitiva.scraper_label"
                    class="font-bold title"
                    >
                    DEFINITIVA - {{ pedido.avaliacao_definitiva.scraper_label }}
                  </span>
                  <span
                    v-else-if="pedido.avaliacao_definitiva.review_model_obj"
                    class="font-bold title"
                  >
                    DEFINITIVA - {{pedido.avaliacao_definitiva.review_model_obj.label}}
                  </span>
                  <span class="font-bold title" v-else-if="pedido.avaliacao_definitiva.creator">
                    DEFINTIVA - {{pedido.avaliacao_definitiva.creator}}
                  </span>
                  <span class="font-bold title" v-else>
                    DEFINTIVA
                  </span>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card-title>
          <v-card-text class="py-0">
            <v-list dense class="py-0">
              <v-list-item v-if="pedido.avaliacao_definitiva.resultado==null">
                  <v-progress-linear indeterminate :color="displayAvalColor(pedido.avaliacao_definitiva.resultado)"/>
              </v-list-item>
              <!-- <v-list-item
                v-if="pedido.avaliacao_definitiva.completed"
                class="text-caption font-italic" 
                :to="{name: 'review', params: {reviewid: url_to_id(pedido.avaliacao_definitiva.review)}}"
              > -->
              <v-list-item v-if="pedido.avaliacao_definitiva.comentario">
                  <v-list-item-avatar align="center" class="ba-0">
                      <v-icon :color="displayAvalColor(null)">mdi-alert-circle-outline</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                      <span>{{ pedido.avaliacao_definitiva.comentario }}</span>
                  </v-list-item-content>
              </v-list-item>
              <v-list-item v-if="pedido.avaliacao_definitiva.resultado=='E'">
                  <v-list-item-avatar>
                      <v-icon :color="displayAvalColor('E')">{{displayAvalIcon('E')}}</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                      <span class="font-italic">{{ pedido.avaliacao_definitiva.exception }}</span>
                  </v-list-item-content>
              </v-list-item>
              <v-list-item
                v-if="pedido.avaliacao_definitiva.completed"
                class="text-caption font-italic" 
              >
                  <v-list-item-content v-if="pedido.avaliacao_definitiva.completed">
                      <span>Completed at: {{ formattedDate(pedido.avaliacao_definitiva.completed) }}</span>
                      <span v-if="pedido.avaliacao_definitiva.editado">Last Edit: {{pedido.avaliacao_definitiva.editor}}-{{formattedDate(pedido.avaliacao_definitiva.editado)}}</span>
                  </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card-text>
        </v-card>
        <v-container>
          
        </v-container>
        <v-expansion-panels accordion tile hover>
          <!-- <v-expansion-panel v-for="rg of reviewgroups.reviewgroups.list" :key="rg.url">
            <GroupedAvaliacao
            v-if="rg.avaliacoes.length"
            :avals="rg.avaliacoes"
            :group="rg"
            :pedido="pedido"
            class="mx-0 px-0"
            @avals_updated="updated_avals()"
            />
          </v-expansion-panel> -->
          <v-expansion-panel v-for="aval of avaliacoes.avaliacoes" :key="aval.url">
            <Avaliacao 
              :avaliacao="aval"
              :pedido="pedido"
              :nome="''"
              :obfuscado="false"
            />
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card-text>
      <v-card-actions>
        <v-btn text
          :disabled="avaliacoes.avaliacoes_prev === null"
          @click="fetch_avals(avaliacoes.avaliacoes_prev)"
        >
          <v-icon>mdi-chevron-left</v-icon> Anterior
        </v-btn>
        <v-spacer></v-spacer>
        {{ avaliacoes.avaliacoes_pg }} / {{ avaliacoes.avaliacoes_tot_pgs }}
        <v-spacer></v-spacer>
        <v-btn text
          :disabled="avaliacoes.avaliacoes_next === null"
          @click="fetch_avals(avaliacoes.avaliacoes_next)"
        >
          Próximo <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { mapState } from 'vuex'

import Avaliacao from './Avaliacao'
import NovaAvaliacao from './NovaAvaliacao'
// import GroupedAvaliacao from './GroupedAvaliacao'
import AvaliacoesListHover from './AvaliacoesListHover.vue'
import avalStatus from '../mixins/avalStatus';
import dateFmt from '../mixins/dateFmt';

export default {
  components: {
    Avaliacao,
    NovaAvaliacao,
    // GroupedAvaliacao,
    AvaliacoesListHover,
  },

  mixins: [
    avalStatus,
    dateFmt
  ],

  async created () {
    await this.fetch_avals();
  },

  props: {
    pedido: Object,
    nome: String,
  },

  computed: {
    ...mapState(['avaliacoes', 'account',]),
  },

  methods: {
    filter_avals_by_status(status) {
      return this.avaliacoes.avaliacoes.filter(aval=>aval.resultado==status)
    },

    async fetch_avals(url) {
      this.loading = true
      await this.$store.dispatch(
        'avaliacoes/get_avaliacoes',
        {url, pedido: this.pedido.id}
      )
      this.$emit('avals_data')
      this.loading = false
    },

    url_to_id(url) {
      let re = /\/(\d*)\/$/i
      let match = re.exec(url)
      if (match)
        return match[1]
      else
        return null
    },

    async updated_avals() {
      await this.fetch_avals()
      this.$emit('avals_updated')
    },

    contains(resultado) {
      for(let aval of this.avaliacoes.avaliacoes) {
        if (aval.resultado == resultado)
          return true
      }
      return false
    },
  },

  data: () => {
    return {
      loading: true,
      error: null,
      status: {
        ERRO: 'E',
        REPROVADO: 'R',
        PENDENTE: 'P',
        APROVADO: 'A',
        EXECUTING: 'EX'
      },
    }
  },

};
</script>
