import axios from 'axios'

const state = {
  total: {
    pedidos: [],
    pedidos_prev: null,
    pedidos_next: null,
    pedidos_pg: null,
    pedidos_tot_pgs: null,
  },
  erros: {
    pedidos: [],
    pedidos_prev: null,
    pedidos_next: null,
    pedidos_pg: null,
    pedidos_tot_pgs: null,
  },
  reprovados: {
    pedidos: [],
    pedidos_prev: null,
    pedidos_next: null,
    pedidos_pg: null,
    pedidos_tot_pgs: null,
  },
}

const getters = {
  getPedidoById: (state) => (id) => {
    return state.total.pedidos.find(op => op.id === id)
  },
}

const mutations = {
  clear_pedidos (state) {
    for (let list in state){
      list.pedidos = []
      list.pedidos_prev = null
      list.pedidos_next = null
      list.pedidos_pg = null
      list.pedidos_tot_pgs = null
    }
  },

  set_pedidos (state, { resultado, results, prev, next, pg, tot }) {
    let list = state.total
    if (resultado === 'E')
      list = state.erros
    if (resultado === 'R')
      list = state.reprovados

    list.pedidos = results
    list.pedidos_prev = prev
    list.pedidos_next = next
    list.pedidos_pg = pg
    list.pedidos_tot_pgs = tot
  },

  add_pedido (state, result) {
    let idx = state.total.pedidos.findIndex(op => op.id === result.id)
    if (idx !== -1) {
      state.total.pedidos.splice(idx, 1, result)
    } else {
      state.total.pedidos.push(result)
    }
  },
}

const actions = {
  async get_pedidos ({ commit, rootGetters }, {url, resultado, review}) {
    if (!url)
      url = process.env.VUE_APP_API_URL + `/pedidosdereview/`
    url += `?resultado=${resultado}&review=${review}`

    let response = await axios
      .get(url,
        { headers: rootGetters['account/getAuthHeader'], },
      )

      await commit(
        'set_pedidos', 
        {
          'resultado': resultado,
          'results': response.data.results,
          'prev': response.data.previous,
          'next': response.data.next,
          'pg': response.data.page,
          'tot': response.data.total_pages,
        }
      )
  },

  async getResource ({ rootGetters }, url) {
    let response = await axios
      .get(url,
        { headers: rootGetters['account/getAuthHeader'], }
      )

    return response.data
  },

  async get_pedido_pxdata ({ dispatch, commit }, id) {
    let url = process.env.VUE_APP_API_URL + `/pedidosdereview/${id}/?pxdata=1`
    let response = await dispatch('getResource', url)
    await commit('add_pedido', response)
    return response
  },

  async get_pedido ({ dispatch, commit }, id) {
    let url = process.env.VUE_APP_API_URL + `/pedidosdereview/${id}/`
    let response = await dispatch('getResource', url)
    await commit('add_pedido', response)
    return response
  },

  async reaval_pedido ({ rootGetters }, id) {
    let url = process.env.VUE_APP_API_URL + `/pedidosdereview/${id}/reavaliar/`
    let response = await axios
      .post(url,
        null,
        { headers: rootGetters['account/getAuthHeader'], }
      )

    return response.data
  },

  async retry_failed_reports_pedido ({ rootGetters }, id) {
    let url = process.env.VUE_APP_API_URL + `/pedidosdereview/${id}/retry_failed_reports/`
    let response = await axios
      .post(url,
        null,
        { headers: rootGetters['account/getAuthHeader'], }
      )

    return response.data
  },


}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}
