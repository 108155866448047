<template>
  <v-list-item :to="{ name: 'review', params: { reviewid: reviewmodel.id } }">
    <v-list-item-avatar>
      <v-icon :color="reviewmodel.active ? 'green darken-2' : 'blue-grey'" large>
        mdi-{{!reviewmodel.manual ? 'abacus': 'account'}}
      </v-icon>
    </v-list-item-avatar>
    <v-list-item-content>
      <div class="pb-1 pt-2">
        {{ reviewmodel.label }}
      </div>
      <div class="py-1">
        <span>{{ formattedDate(reviewmodel.created) }}</span>
      </div>
      <div v-if="reviewmodel.creator" class="py-1">
        <span>{{ reviewmodel.creator }}</span> 
      </div>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
import avalStatus from '../mixins/avalStatus';
import dateFmt from '../mixins/dateFmt'

export default {
  props: {
    reviewmodel: Object,
  },

  mixins: [dateFmt, avalStatus],
};
</script>
