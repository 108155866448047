import Vue from 'vue'
import Router from 'vue-router'
import Home from './views/Home.vue'
import PedidoReview from './views/PedidoReview.vue'
import Review from './views/Review.vue'

Vue.use(Router)

function castPedidoReviewID(route) {
  return {
    pedidoid: Number(route.params.pedidoid),
  };
}

function castReviewID(route) {
  return {
    reviewid: Number(route.params.reviewid),
  };
}

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    { path: '/', name: 'home', component: Home },
    { path: '/pedido/:pedidoid', name: 'pedido', component: PedidoReview, props: castPedidoReviewID },
    { path: '/review/:reviewid', name: 'review', component: Review, props: castReviewID },
  ]
})
