<template>
  <v-card>
    <v-overlay
      v-if="obfuscado"
      absolute
      opacity="1"
      color="white"
      light
      z-index="4"
    >
      <span class="indigo--text title">Avalie o pedido para ver este dado</span>
    </v-overlay>
    <v-card-title class="py-2">
      <div class="text-center mr-2"> 
        <v-icon x-large :color="displayAvalColor(avaliacao.resultado)" v-if="avaliacao.resultado === 'A'">{{displayAvalIcon(avaliacao.resultado)}}</v-icon>
        <v-icon x-large :color="displayAvalColor(avaliacao.resultado)" v-if="avaliacao.resultado === 'R'">{{displayAvalIcon(avaliacao.resultado)}}</v-icon>
        <v-icon x-large :color="displayAvalColor(avaliacao.resultado)" v-if="avaliacao.resultado === 'E'">{{displayAvalIcon(avaliacao.resultado)}}</v-icon>
        <v-icon x-large :color="displayAvalColor(avaliacao.resultado)" v-if="avaliacao.resultado === 'P'">{{displayAvalIcon(avaliacao.resultado)}}</v-icon>
        <v-icon x-large :color="displayAvalColor(avaliacao.resultado)" v-if="!avaliacao.resultado">{{displayAvalIcon(avaliacao.resultado)}}</v-icon>
      </div>
        <div>
          <span v-if="avaliacao.definitivo">
            <v-icon color="yellow darken-2" class="mr-2">
              mdi-shield-star-outline
            </v-icon>
        </span>
        <span v-if="avaliacao.scraper_label"> {{ avaliacao.scraper_label }} </span>
        <span v-else-if="avaliacao.review_model_obj"> {{ avaliacao.review_model_obj.label }} </span>
        <span v-else-if="avaliacao.creator"> {{ " " + avaliacao.creator }} </span>
        <span v-else>Avaliação</span>
      </div>
      <v-spacer />
      <NovaAvaliacao
        v-if="!avaliacao.definitivo && avaliacao.resultado === 'P'"
        :avaliacao="avaliacao"
        :pedido="pedido"
      />
    </v-card-title>
    <v-card-text class="pb-2">
      <div v-if="avaliacao.comentario">
        <!-- <v-icon color="yellow darken-3" class="mr-1">mdi-alert-circle-outline</v-icon> -->
        <span class="text-subtitle-1">
          {{ avaliacao.comentario }}
        </span>
      </div>
      <div v-if="avaliacao.resultado === 'E'" class="pt-1 text-caption">
        {{ avaliacao.exception }}
      </div>
      <div v-if="avaliacao.completed" class="pt-1 text-caption">
        Completed at: {{ formattedDate(avaliacao.completed) }}
      </div>
      <div v-if="avaliacao.editor" class="pt-1 text-caption">
        Editado por {{ avaliacao.editor }} em {{ formattedDate(avaliacao.editado) }}
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import NovaAvaliacao from './NovaAvaliacao'
import dateFmt from '../mixins/dateFmt'
import avalStatus from '../mixins/avalStatus';

export default {
  components: {
    NovaAvaliacao,
  },

  mixins: [
    dateFmt,
    avalStatus
  ],

  props: {
    avaliacao: Object,
    pedido: Object,
    obfuscado: Boolean,
    nome: String,
  },

  computed: {
    is_google_pep_relative() {
      return /Há pessoas na lista de PEPs da CGU que compartilham sobrenomes com a pessoa avaliada/i.test(this.avaliacao.comentario)
    },
  },
};
</script>
