import axios from 'axios'

const state = {
    reviewmodels: {
        list: [],
        prev: null,
        next: null,
        pg: null,
        tot_pgs: null,
    },

    reviewmodels_manual: {
        list: [],
        prev: null,
        next: null,
        pg: null,
        tot_pgs: null,
    },
}

const getters = {
    getReviewModelById: (state) => (id) => {
        return state.reviewmodels.list.find(r => r.id === id)
    },

    getReviewModelByUrl: (state) => (url) => {
        return state.reviewmodels.list.find(r => r.url === url)
    },
}

const mutations = {
    clear_reviewmodels(state) {
        state.reviewmodels.list = []
        state.reviewmodels.prev = null
        state.reviewmodels.next = null
        state.reviewmodels.pg = null
        state.reviewmodels.tot_pgs = null

        state.reviewmodels_manual.list = []
        state.reviewmodels_manual.prev = null
        state.reviewmodels_manual.next = null
        state.reviewmodels_manual.pg = null
        state.reviewmodels_manual.tot_pgs = null
    },

    set_reviewmodels(state, {results, prev, next, pg, tot}) {
        state.reviewmodels.list = results
        state.reviewmodels.prev = prev
        state.reviewmodels.next = next
        state.reviewmodels.pg = pg
        state.reviewmodels.tot_pgs = tot
    },

    set_reviewmodels_manual(state, {results, prev, next, pg, tot}) {
        state.reviewmodels_manual.list = results
        state.reviewmodels_manual.prev = prev
        state.reviewmodels_manual.next = next
        state.reviewmodels_manual.pg = pg
        state.reviewmodels_manual.tot_pgs = tot
    },
}

const actions = {
    async get_reviewmodels({commit, rootGetters}, {url, manual}) {
        if (!url) {
            url = process.env.VUE_APP_API_URL + '/reviewmodels/'
            url += `?manual=${manual}`
        }

        let response = await axios.get(
            url,
            {headers: rootGetters['account/getAuthHeader'],},
        )

        if (manual) {
            await commit(
                'set_reviewmodels_manual',
                {
                    'results': response.data.results,
                    'prev': response.data.previous,
                    'next': response.data.next,
                    'pg': response.data.page,
                    'tot': response.data.total_pages,
                }
            )
        }
        else {
            await commit(
                'set_reviewmodels',
                {
                    'results': response.data.results,
                    'prev': response.data.previous,
                    'next': response.data.next,
                    'pg': response.data.page,
                    'tot': response.data.total_pages,
                }
            )
        }
    },

    async getResource ({ rootGetters }, url) {
        let response = await axios.get(
            url,
            {headers: rootGetters['account/getAuthHeader'],},
        )

        return response.data
    },

    async get_reviewmodel({dispatch}, url) {
        let response = await dispatch('getResource', url)
        return response
    },

    async get_reviewmodel_by_id ({ dispatch }, id) {
        let url = process.env.VUE_APP_API_URL + `/reviewmodels/${id}/`
        let response = await dispatch('getResource', url)
        return response
    },
}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
}
