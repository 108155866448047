<template>
  <div>
    <v-progress-circular v-if="isLoading || !avalData" indeterminate class="mt-2" />
    <div v-else>
      <div v-if="avalData.length > 0">
        <v-card elevation="3" class="pa-1 mt-2">
          <v-card-title class="subtitle-2">
            Google com Termos Negativos
          </v-card-title>
          <v-simple-table dense>
            <thead>
              <tr>
                <th>URL</th>
                <th>Nome</th>
                <th>Título</th>
                <th>Link</th>
                <th>Trecho</th>
                <th>Report</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(g, i) in avalData" :key="i">
                <td>{{ g.url }}</td>
                <td>{{ g.display_name }}</td>
                <td>{{ g.title }}</td>
                <td>{{ g.link }}</td>
                <td>{{ g.snippet }}</td>
                <td>{{ g.report }}</td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-card>
      </div>
      <div v-else>
        <v-card>
        <v-simple-table class="mt-2" dense>
          <thead>
            <tr>
              <th>
                Nada consta
              </th>
            </tr>
          </thead>
        </v-simple-table>
      </v-card>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import dateFmt from '../mixins/dateFmt'

export default {
  props: {
    avalId: Number,
  },

  mixins: [
    dateFmt
  ],

  computed: {
    ...mapState(['avaliacoes'])
  },

  data() {
    return {
      isLoading: true,
      avalData: null
    }
  },

  mounted() {
    this.init()
  },

  methods: {
     init () {
      this.isLoading = true
      this.avalData = null

      this.$store.dispatch('avaliacoes/get_data', this.avalId, 'serasa').then((data) => {
        this.avalData = data
        this.isLoading = false
      })
      console.log(this.avalData)
    }
  }
}
</script>
