<template>
  <v-list-item :to="{ name: 'pedido', params: { pedidoid: pedido.id } }">
    <v-list-item-avatar>
      <v-icon color="blue-grey" large>mdi-account-cash</v-icon>
    </v-list-item-avatar>
    <v-list-item-content>
      <div class="pb-1 pt-2">
        {{ pedido.documento }} - {{ pedido.display_name || '?' }}
          <v-icon v-if="pedido.avaliado_por_mim" color="green" small>mdi-check-circle-outline</v-icon>
          <v-icon v-if="pedido.avaliacao_definitiva" color="yellow darken-2" small>mdi-shield-star-outline</v-icon>
      </div>
      <div class="py-1">
        <v-icon
        class="pr-5 pl-1"
          :color="displayAvalColor(pedido.resultado)"
        >
          {{displayAvalIcon(pedido.resultado)}}
        </v-icon>
        <span>{{ displayStatus(pedido.status) }}</span>
      </div>
      <div class="py-1">
        <span>{{ pedido.creator }}, {{ displayCreated }}</span> 
      </div>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
import avalStatus from '../mixins/avalStatus';
import dateFmt from '../mixins/dateFmt'
import displayStatus from '../mixins/displayStatus'

export default {
  props: {
    pedido: Object,
  },

  mixins: [dateFmt, displayStatus, avalStatus],

  computed: {
    displayCreated: function() {
      return this.formattedDate(this.pedido.created)
    },
  }

};
</script>
