export default {
    methods: {
        displayAvalColor(resultado) {
            switch (resultado) {
                case 'A':
                    return 'green'
                case 'R':
                    return 'red'
                case 'P':
                    return 'orange darken-2'
                case 'E':
                    return 'red darken-4'
                case null:
                    return 'blue-grey darken-2'
                default:
                    return 'red darken-4'
            }
        },

        displayAvalIcon(resultado) {
            switch (resultado) {
                case 'A':
                    return 'mdi-thumb-up-outline'
                case 'R':
                    return 'mdi-thumb-down-outline'
                case 'P':
                    return 'mdi-pencil-box-outline'
                case 'E':
                    return 'mdi-alert-circle-outline'
                case null:
                    return 'mdi-clock-outline'
                default:
                    return 'mdi-skull-crossbones-outline'
            }
        },
    },
}