<template>
  <div>
    <v-progress-circular v-if="isLoading || !avalData" indeterminate class="mt-2"/>
    <div v-else>
      <v-card elevation="3" class="pa-1 mt-2">
        <v-simple-table dense >
          <thead>
            <tr>
              <th>Nada consta</th>
            </tr>
          </thead>
        </v-simple-table>
      </v-card>
      <v-card v-if="avalData.nadaConsta === false" elevation="3" class="pa-1 mt-3">
        {{ avalData.content }}
      </v-card>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import dateFmt from '../mixins/dateFmt'

export default {
  props: {
    avalId: Number,
  },

  mixins: [
    dateFmt
  ],

  computed: {
    ...mapState(['avaliacoes'])
  },

  data() {
    return {
      isLoading: true,
      avalData: null
    }
  },

  mounted() {
    this.init()
  },

  methods: {
    init () {
      this.isLoading = true
      this.avalData = null

      this.$store.dispatch('avaliacoes/get_data', this.avalId, 'serasa').then((data) => {
        this.avalData = data
        this.isLoading = false
      })
    } 
  }
}
</script>
