import axios from 'axios'

const state = {
  reviews: {
    list: [],
    prev: null,
    next: null,
    pg: null,
    tot_pgs: null,
  },
}

const getters = {
  getReviewById: (state) => (id) => {
    let x = state.reviews.list.find(mod => mod.id === id)
    return x
  },
  getReviewByURL: (state) => (url) => {
    let x = state.reviews.list.find(mod => mod.url === url)
    return x
  },
}

const mutations = {
  clear_reviews (state) {
    state.reviews.list = []
    state.reviews.prev = null
    state.reviews.next = null
    state.reviews.pg = null
    state.reviews.tot_pgs = null
  },

  set_reviews (state, { results, prev, next, pg, tot }) {
    state.reviews.list = results
    state.reviews.prev = prev
    state.reviews.next = next
    state.reviews.pg = pg
    state.reviews.tot_pgs = tot
  },

  add_review (state, result) {
    let idx = state.reviews.list.findIndex(mod => mod.id === result.id)
    if (idx !== -1) {
      state.reviews.list.splice(idx, 1, result)
    } else {
      state.reviews.list.push(result)
    }
  },
}

const actions = {
  async get_reviews ({ commit, rootGetters }, url) {
    if (!url) {
      url = process.env.VUE_APP_API_URL + `/reviewmodules/`
    }

    let response = await axios
      .get(url,
        { headers: rootGetters['account/getAuthHeader'], },
      )

      await commit('set_reviews',
        { 'results': response.data.results,
          'prev': response.data.previous,
          'next': response.data.next,
          'pg': response.data.page,
          'tot': response.data.total_pages,
        })
  },

  async new_review ({ commit, rootGetters },
      { nome, params, tipo, ativo, modulo }) {

    let data = {
      nome: nome,
      parametros: params,
      tipo_de_review: tipo,
      ativo: ativo,
      modulo: modulo,
    }

    let response = await axios
      .post(process.env.VUE_APP_API_URL + `/reviewmodules/`,
        data,
        { headers: rootGetters['account/getAuthHeader'], }
      )

    await commit('add_review', response.data)
    return response.data
  },

  async getResource ({ rootGetters }, url) {
    let response = await axios
      .get(url,
        { headers: rootGetters['account/getAuthHeader'], }
      )

    return response.data
  },

  async get_review ({ dispatch, commit }, url) {
    let response = await dispatch('getResource', url)
    await commit('add_review', response)
    return response
  },

  async get_review_by_id ({ dispatch, commit }, id) {
    let url = process.env.VUE_APP_API_URL + `/reviewmodules/${id}/`
    let response = await dispatch('getResource', url)
    await commit('add_review', response)
    return response
  },

}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}
