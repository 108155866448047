<template>
    <v-dialog
        transition="dialog-bottom-transition"
        max-width="600"
    >
        <template v-slot:activator="{ on, attrs }">
        <v-btn
            class="my-1"
            small
            v-bind="attrs"
            v-on="on"
        >{{ button }}</v-btn>
        </template>
        <template v-slot:default="dialog">
        <v-card>
            <v-toolbar
            color="primary"
            dark
            >{{ title }}</v-toolbar>
            <v-card-text>
            <div class="text pa-12">{{ body }}</div>
            </v-card-text>
            <v-card-actions class="justify-end">
            <v-btn
                text
                @click="dialog.value = false"
            >Close</v-btn>
            </v-card-actions>
        </v-card>
        </template>
    </v-dialog>
</template>

<script>
export default {
  name: 'ShowModal',
  props: {
    body: {
      type: String,
      default: null
    },
    title: {
      type: String,
      default: ''
    },
    button: {
      type: String,
      default: 'Mostrar'
    },
  },
  data () {
    return {
    }
  },
}
</script>