<template>
  <v-dialog v-model="dialog" width="600">
    <template v-slot:activator="{ on }">
      <v-btn x-small outlined fab color="indigo" v-on="on" :disabled="Boolean(pedido.avaliacao_definitiva)">
        <v-icon v-if="!avaliacao">mdi-plus</v-icon>
        <v-icon v-else>mdi-square-edit-outline</v-icon>
      </v-btn>
    </template>
      <v-card>
        <v-card-title>
          <span v-if="!avaliacao">Adicionar Avaliação</span>
          <span v-else>Editar Avaliação</span>
        </v-card-title>
        <v-card-text>
          <v-form>
            <div class="d-flex justify-space-around mt-2 mb-5">

              <div class="text-center"><v-btn :outlined="resultado != 'A'" fab :color="resultado === 'A' ? 'green' : 'grey'" height="64" width="64" dark
                @click="resultado = 'A'"
              >
                <v-icon size="40">mdi-thumb-up-outline</v-icon>
              </v-btn><div class="text-center mt-1">Aprovado</div></div>

              <div class="text-center"><v-btn :outlined="resultado != 'R'" fab :color="resultado === 'R' ? 'red' : 'grey'" height="64" width="64" dark
                @click="resultado = 'R'"
              >
                <v-icon size="40">mdi-thumb-down-outline</v-icon>
              </v-btn><div class="text-center mt-1">Reprovado</div></div>

              <div class="text-center"><v-btn :outlined="resultado != 'P'" fab :color="resultado === 'P' ? 'orange darken-2' : 'grey'" height="64" width="64" dark
                @click="resultado = 'P'"
              >
                <v-icon size="40">mdi-file-document-edit-outline</v-icon>
              </v-btn><div class="text-center mt-1">Pendente</div></div>
            </div>
              <v-textarea
                outlined
                v-model="comentario"
                label="Comentário"
                :error-messages="errors.comentario"
                rows="15"
              ></v-textarea>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-checkbox
            class="ml-3"
            label="Avaliação Definitiva"
            v-model="definitivo"
            @click.native="definitivo ? definitivoDialog = true : null"
          ></v-checkbox>
          <v-spacer />
          <v-btn text @click="dialog = false">Cancelar</v-btn>
          <v-btn
            text
            @click="createAval()"
            :disabled="resultado === null"
            v-if="!avaliacao"
          >Salvar</v-btn>
          <v-btn
            text
            @click="updateAval()"
            :disabled="resultado === null"
            v-else
          >Atualizar</v-btn>
        </v-card-actions>
      </v-card>
      <v-dialog
        persistent
        v-model="definitivoDialog"
        max-width="400"
      >
        <v-card>
          <v-card-title>Avaliação Definitiva</v-card-title>
          <v-card-text>Marcar esta avaliação como definitiva pode desencadear desembolsos para a Upp. Certifique-se de que esta avaliação é correta antes de continuar.</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="definitivo = false; definitivoDialog = false">Cancelar</v-btn>
            <v-btn text @click="definitivo = true; definitivoDialog = false">Continuar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-dialog>
</template>

<script>
export default {
  props: {
    pedido: Object,
    avaliacao: Object,
  },

  data: function() {
    return {
      dialog: false,
      definitivoDialog: false,

      resultado: this.avaliacao ? this.avaliacao.resultado : null,
      definitivo: this.avaliacao ? this.avaliacao.definitivo : false,
      comentario: this.avaliacao ? this.comentario : null,

      errors: {
        resultado: null,
        comentario: null,
      },

      non_field_errors: null,
    }
  },

  methods: {
    createAval: async function() {
        this.errors = {}
        this.non_field_errors = null

        await this.$store.dispatch('avaliacoes/new_avaliacao',
          {
            resultado: this.resultado,
            definitivo: this.definitivo,
            comentario: this.comentario,
            pedido: this.pedido.url,
          })

        this.dialog = false
        this.$store.dispatch('pedidos/get_pedido', this.pedido.id)
        this.$emit('avals_updated')
    },
    updateAval: async function() {
        this.errors = {}
        this.non_field_errors = null

        await this.$store.dispatch('avaliacoes/update_avaliacao',
          {
            resultado: this.resultado,
            definitivo: this.definitivo,
            comentario: this.comentario,
            pedido: this.pedido.url,
            avaliacao: this.avaliacao,
          })

        this.dialog = false
        // this.$store.dispatch('pedidos/get_pedido', this.pedido.id)
        this.$store.dispatch('reviewgroups/get_reviewgroups', {pedido: this.pedido.id})
        this.$emit('avals_updated')
    },
  },

};
</script>
