<template>
  <v-container>
    <v-row>
      <v-col>
        <PedidosList resultado=""/>
      </v-col>
      <v-col>
        <ReviewModelsList :manual="false"/>
        <ReviewModelsList :manual="true"/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import PedidosList from './PedidosList'
import ReviewModelsList from './ReviewModelsList.vue';

export default {
  components: {
    PedidosList,
    ReviewModelsList,
  },
};
</script>
